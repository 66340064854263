<template>
  <div class="vertify-code">
    <img :src="src" alt="点击刷新" @click="refreshCode">
  </div>
</template>

<script>
import { getRandomString } from 'U'
export default {
  data() {
    return {
      canvas: null,
      width: 100,
      height: 40,
      ctx: null,
      src: ''
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.canvas = document.createElement('canvas');
      this.canvas.width = this.width;
      this.canvas.height = this.height;
      this.ctx = this.canvas.getContext("2d");
      this.refreshCode();
    },
    refreshCode() {
      let vertifyCode = getRandomString(4);
      this.$emit('get-code', vertifyCode);
      // 清空画布
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // 画布背景
      this.ctx.fillStyle = "cornflowerblue"; //画布填充色
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height); //清空画布
      // 字体样式
      this.ctx.fillStyle = "white"; //设置字体颜色
      this.ctx.font = "20px Arial"; //设置字体
      // 画验证码字符串
      let x = 0, y = 25;
      for(let i=0; i<vertifyCode.length; i++) {
        x = i*20+15;
        this.ctx.fillText(vertifyCode[i], x, y);
      }
      // 重置线条
      this.ctx.beginPath();
      // 画随机线
      for(let i=0; i<3; i++) {
        this.drawLine();
      }
      // 画随机点
      for(let i=0; i<20; i++) {
        this.drawDot();
      }
      this.src = this.canvas.toDataURL("image/png");
    },
    drawLine() {
      this.ctx.moveTo(Math.floor(Math.random() * this.canvas.width), Math.floor(Math.random() * this.canvas.height)); //随机线的起点x坐标是画布x坐标0位置，y坐标是画布高度的随机数
      this.ctx.lineTo(Math.floor(Math.random() * this.canvas.width), Math.floor(Math.random() * this.canvas.height)); //随机线的终点x坐标是画布宽度，y坐标是画布高度的随机数
      this.ctx.lineWidth = 0.5; //随机线宽
      this.ctx.strokeStyle = 'rgba(50,50,50,0.3)'; //随机线描边属性
      this.ctx.stroke(); //描边，即起点描到终点
    },
    drawDot() {
      let px = Math.floor(Math.random() * this.canvas.width);
      let py = Math.floor(Math.random() * this.canvas.height);
      this.ctx.moveTo(px, py);
      this.ctx.lineTo(px + 1, py + 1);
      this.ctx.lineWidth = 0.2;
      this.ctx.stroke();
    }
  }
}
</script>

<style lang="scss" scoped>
.vertify-code {
  width: 100%;
  height: 100%;
}
</style>