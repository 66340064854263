import CryptoJS from 'crypto-js';

// 加密数据
export function encryptData(data, str = "274f13cb95488c04r127678926031ay6",str_iv="32dc26e8a4b0251g") {
    let key = CryptoJS.enc.Utf8.parse(str);
    let iv = CryptoJS.enc.Utf8.parse(str_iv);
    // 加密内容
    const content = JSON.stringify(data);
    // 加密方法
    const encryptedContent = CryptoJS.AES.encrypt(content, key, {
        iv:iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    const encStr = encryptedContent.toString();
    return encStr
}

//解密数据
export function decryptData(encStr, str = "274f13cb95488c04r127678926031ay6",str_iv="32dc26e8a4b0251g") {
    if (!encStr) return
    let key = CryptoJS.enc.Utf8.parse(str);
    let iv = CryptoJS.enc.Utf8.parse(str_iv);

    // 解密方法
    const decryptedContent = CryptoJS.AES.decrypt(
        // CryptoJS.format.Hex.parse(encStr),
        encStr,
        key, //注意：后面这里最好使用 CryptoJS.format.Utf8.parse(key)
        {
            iv:iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
    );
    return JSON.parse(CryptoJS.enc.Utf8.stringify(decryptedContent))
}