<template>
  <div class="login">
    <canvas class="canvass"></canvas>
    <div class="login-card">
      <div class="login-header">
        <div class="login-logo"></div>
        <div class="login-title">
          <div class="welcome">欢迎登录</div>
          <div class="system-name">电梯安全公共信息服务平台</div>
          <div class="system-desc">物联网预警 / 电梯维保 / AI识别 / 应急救援一站式解决方案</div>
        </div>
      </div>
      <a-form-model ref="loginForm" :model="formData" :rules="formRules" class="login-form normal-form">
        <a-form-model-item prop="username">
          <a-input v-model.trim="formData.username" placeholder="账户" size="large">
            <a-icon slot="prefix" type="user" style="color: #1890ff" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input v-model.trim="formData.password" type="password" placeholder="密码" size="large">
            <a-icon slot="prefix" type="lock" style="color: #1890ff" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="code">
          <div style="display: flex;">
            <a-input v-model.trim="formData.code" @keyup.enter.native="checkFormData" placeholder="验证码" size="large" style="flex: 1;">
              <a-icon slot="prefix" type="safety-certificate" style="color: #1890ff" />
            </a-input>
            <vertify-code ref="vertifyCode" @get-code="getVertifyCode" style="width: 123px;flex-shrink: 0;margin-left: 10px;"></vertify-code>
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <a-checkbox :checked="formData.autologin" @change="formData.autologin=!formData.autologin" style="color: white">自动登录</a-checkbox>
        </a-form-model-item>
        <a-form-model-item>
          <a-button  size="large" @click="checkFormData" style="width: 100%;background-color:transparent;color: white">
            登录
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="compony-info">Copyright © 2020 南京优湖科技有限公司版权所有</div>
  </div>
</template>
<script>
import CryptoJS from "crypto-js";
import {decryptData, encryptData} from "U/crypto-js";
import { setCache, getCache } from '@/utils'
import {getUserByUserid, login} from 'A/login'
import VertifyCode from 'C/VertifyCode'
import {getTreeOfUserDepHasUser} from "A/jcgn";
export default {
  name: 'login',
  components: {
    VertifyCode
  },
  data() {
    return {
      areaid:'',
      phonename:'',
      phonepassword:'',
      phonextnum:'',
      formData: {
        username: '',
        password: '',
        code: '',
        autologin: false
      },
      formRules: {
        username: [{required: true, message: '请输入账户'}],
        password: [{required: true, message: '请输入密码'}],
        code: [
          {required: true, message: '请输入验证码'},
          {
            validator: (rule, value, callback) => {
              if(value.toUpperCase() === this.vertifyCode.toUpperCase()) {
                callback();
              }else {
                callback('验证码错误')
              }
            }
          }
        ],
      },
      vertifyCode: '',
    }
  },
  mounted() {
    let PI = Math.PI,cos = Math.cos,acos = Math.acos,sin = Math.sin,asin = Math.asin,abs = Math.abs,sqrt = Math.sqrt,pow = Math.pow,floor = Math.floor,round = Math.round,random = Math.random,atan2 = Math.atan2;
    let HALF_PI = 0.5 * PI;
    let TAU = 2 * PI;
    let QT3_TAU = TAU - HALF_PI;
    let TO_RAD = PI / 180;
    let rand = function rand(n) {return n * random();};
    let randRange = function randRange(n) {return n - rand(2 * n);};
    let fadeIn = function fadeIn(t, m) {return t / m;};
    let fadeOut = function fadeOut(t, m) {return (m - t) / m;};
    let fadeInOut = function fadeInOut(t, m) {
      let hm = 0.5 * m;
      return abs((t + hm) % m - hm) / hm;
    };
    let dist = function dist(x1, y1, x2, y2) {return sqrt(pow(x2 - x1, 2) + pow(y2 - y1, 2));};
    let angle = function angle(x1, y1, x2, y2) {return atan2(y2 - y1, x2 - x1);};
    let lerp = function lerp(n1, n2, speed) {return (1 - speed) * n1 + speed * n2;};

    let deflectorCount = 50;
    let particleCount = 500;

    let canvas = void 0;
    let ctx = void 0;
    let origin = void 0;
    let mouse = void 0;
    let hover = void 0;
    let deflectors = void 0;
    let particles = void 0;

    function setup() {
      canvas = document.createElement('canvas');
      ctx = canvas.getContext('2d');
      canvas.style = '\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\twidth: 100%;\n\t\theight: 100%;\n\t';

      document.body.appendChild(canvas);
      window.canvas=canvas

      origin = {
        x: 0,
        y: 0 };

      mouse = {
        x: 0,
        y: 0 };

      hover = false;
      init();
      draw();
    }

    function init() {
      resize();
      hover = false;

      deflectors = [];
      for (var i = 0; i < deflectorCount; i++) {
        deflectors.push(getDeflector());
      }

      particles = [];
      for (var _i = 0; _i < particleCount; _i++) {
        particles.push(getParticle(_i).create());
      }
    }

    function resize() {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      origin.x = mouse.x = 0.5 * canvas.width;
      origin.y = mouse.y = 0.5 * canvas.height;
    }

    function mouseHandler(e) {
      hover = e.type === "mousemove";
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    }

    function getDeflector() {
      return {
        position: {
          x: rand(window.innerWidth),
          y: rand(window.innerHeight) },

        velocity: {
          x: randRange(1),
          y: randRange(1) },

        threshold: rand(200) + 100,
        direction: rand(TAU),
        move: function move() {
          if (this.position.x > canvas.width || this.position.x < 0) {
            this.velocity.x *= -1;
          }
          if (this.position.y > canvas.height || this.position.y < 0) {
            this.velocity.y *= -1;
          }
          this.position.x += this.velocity.x;
          this.position.y += this.velocity.y;
        } };

    }

    function getParticle(i) {
      return {
        create: function create() {
          this.position.x = this.lastPosition.x = origin.x + randRange(1);
          this.position.y = this.lastPosition.x = origin.y + randRange(1);
          this.speed = rand(5) + 1;
          this.size = rand(3) + 0.5;
          this.life = 0;
          this.ttl = rand(500);
          this.hue = randRange(150);
          this.direction = angle(0.5 * canvas.width, 0.5 * canvas.height, this.position.x, this.position.y);
          return this;
        },
        position: {
          x: 0,
          y: 0 },

        lastPosition: {
          x: 0,
          y: 0 },

        velocity: {
          x: 0,
          y: 0 },

        update: function update() {
          this.life++;
          this.lastPosition.x = this.position.x;
          this.lastPosition.y = this.position.y;
          this.velocity.x = lerp(
              this.velocity.x,
              cos(this.direction) * fadeInOut(this.life, this.ttl) * this.speed,
              0.15);

          this.velocity.y = lerp(
              this.velocity.y,
              sin(this.direction) * fadeInOut(this.life, this.ttl) * this.speed,
              0.15);

          this.position.x += this.velocity.x;
          this.position.y += this.velocity.y;
          this.life > this.ttl && this.create();
        },
        draw: function draw() {
          this.update();
          ctx.beginPath();
          ctx.lineWidth = this.size;
          ctx.strokeStyle = 'hsla(' + this.hue + ',60%,50%,' + fadeInOut(this.life, this.ttl) * 0.5 + ')';
          ctx.moveTo(this.lastPosition.x, this.lastPosition.y);
          ctx.lineTo(this.position.x, this.position.y);
          ctx.stroke();
          ctx.closePath();
        } };

    }

    let deflector = void 0,particle = void 0;

    function draw() {
      let i = void 0,j = void 0;
      origin.x =
          lerp(
              origin.x,
              hover ? mouse.x : 0.5 * canvas.width,
              0.05);

      origin.y =
          lerp(
              origin.y,
              hover ? mouse.y : 0.5 * canvas.height,
              0.05);

      ctx.fillStyle = "rgba(0,0,0,0.05)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      for (i = particles.length - 1; i >= 0; i--) {
        particle = particles[i];
        for (j = deflectors.length - 1; j >= 0; j--) {
          deflector = deflectors[j];

          i === 0 && deflector.move();

          particle.direction =
              dist(
                  particle.position.x,
                  particle.position.y,
                  deflector.position.x,
                  deflector.position.y) <
              deflector.threshold &&
              lerp(
                  particle.direction,
                  angle(
                      deflector.position.x,
                      deflector.position.y,
                      particle.position.x,
                      particle.position.y) +
                  angle(
                      origin.x,
                      origin.y,
                      particle.position.x,
                      particle.position.y),

                  0.075) ||

              particle.direction;
        }

        particle.draw();
      }

      ctx.save();
      ctx.globalCompositeOperation = "lighten";
      ctx.filter = "blur(6px)";
      ctx.drawImage(canvas, 0, 0);
      ctx.restore();

      ctx.save();
      ctx.drawImage(canvas, 0, 0);
      ctx.restore();

      window.requestAnimationFrame(draw);
    }

    window.addEventListener("load", setup);
    window.addEventListener("resize", resize);
    window.addEventListener("mousemove", mouseHandler);
    window.addEventListener("mouseout", mouseHandler);
    // window.addEventListener("click", init);
  },
  created() {
    if(this.$route.params.flag==true){
      this.$router.go(0)
    }
    this.checkAutologin();
  },
  methods: {
    checkAutologin() {
      let logininfo = getCache('logininfo', true);
      if(logininfo&&logininfo.password) {
        this.formData.autologin = true;
        this.formData.username = logininfo.username;
        this.formData.password = logininfo.password;
        this.login();
      }
    },
    getVertifyCode(vertifyCode) {
      this.vertifyCode = vertifyCode;
    },
    checkFormData() {
      this.$refs.loginForm.validate(valid => {
        if(valid) {
          // 自动登录
          if(this.formData.autologin) {
            setCache('logininfo', {
              username: this.formData.username,
              password: this.formData.password,
            }, true);
          }
          this.login();
        }else {
          this.$refs.vertifyCode.refreshCode();
          return false;
        }
      })
    },
    login() {
      this.showLoading();
      let params = {
        useraccount: encryptData(this.formData.username),
        password: encryptData(this.formData.password),
        // useraccount: this.formData.username,
        // password: this.formData.password,
        apptype: '1', // 0:app 1:pc
      }
      login(params).then(res => {
        if(res && res.returncode == '0') {
          this.userid = res.userid.toString();
          this.usertype = res.usertype;
          this.usernames = res.username;
          this.menuLists=res.menu
          if(this.formData.autologin){
            setCache('logininfo', {
              username: this.formData.username,
              password: this.formData.password,
              userdepid:this.userdepid,
              usersid: this.userid,
              usertype:this.usertype,
              usernames:this.usernames,
            }, true);
          }else{
            setCache('logininfo', {
              username: this.formData.username,
              usersid: this.userid,
              usertype: this.usertype,
              usernames: this.usernames,
            }, true);
          }
          this.$store.commit('setToken', 'lift ' + res.token);
          this.$store.commit('setUserInfo', res);
          this.$store.commit('setMenuList', res.menu);
          getTreeOfUserDepHasUser({}).then(res=>{
            if(res&&res.returncode=='0'){
              setCache('userDepLists', {
                userDepList: res.item,
              }, true);
            }
          })
          getUserByUserid({userid: this.userid}).then(res => {
            if (res && res.returncode == '0') {
              this.areaid = res.item.areaid
              this.phonename = res.item.phonename
              this.phonepassword = res.item.phonepassword
              this.phonextnum = res.item.phonextnum
              this.useraccount = res.item.useraccount
              this.playtype = res.item.playtype
              this.userdepid = res.item.userdepid
              this.roleid = res.item.roleList[0].roleid
              if(this.formData.autologin) {
                setCache('logininfo', {
                  useraccount: this.useraccount,
                  username: this.formData.username,
                  password: this.formData.password,
                  usersid: this.userid,
                  usertype: this.usertype,
                  usernames: this.usernames,
                  areacode: this.areaid,
                  phonename: this.phonename,
                  phonepassword: this.phonepassword,
                  phonextnum: this.phonextnum,
                  playtype: this.playtype,
                  userdepid: this.userdepid,
                  roleid: this.roleid,
                }, true);
              }else{
                setCache('logininfo', {
                  useraccount: this.useraccount,
                  username: this.formData.username,
                  usersid: this.userid,
                  usertype: this.usertype,
                  usernames: this.usernames,
                  areacode: this.areaid,
                  phonename: this.phonename,
                  phonepassword: this.phonepassword,
                  phonextnum: this.phonextnum,
                  playtype: this.playtype,
                  userdepid: this.userdepid,
                  roleid: this.roleid,
                }, true);
              }
              for(let i=0;i<this.menuLists.length;i++){
                if(this.menuLists[i].menuid==92){
                  window.removeEventListener("load", window.setup);
                  window.removeEventListener("resize", window.resize);
                  window.removeEventListener("mousemove", window.mouseHandler);
                  window.removeEventListener("mouseout", window.mouseHandler);
                  setTimeout(function (){
                    document.body.removeChild(window.canvas)
                  },250)
                  this.$router.push({
                    name: 'superviseBigData',
                    params: true,
                  })
                  return;
                }else{
                  window.removeEventListener("load", window.setup);
                  window.removeEventListener("resize", window.resize);
                  window.removeEventListener("mousemove", window.mouseHandler);
                  window.removeEventListener("mouseout", window.mouseHandler);
                  setTimeout(function (){
                    document.body.removeChild(window.canvas)
                  },250)
                  this.$router.push({
                    name: 'home',
                  })
                }
              }
              this.$router.push({
                name: 'home',
              })
            }
          })
        }else {
          this.$refs.vertifyCode.refreshCode();
          this.$message.error(res.errormsg||'登录失败');
        }
        this.hideLoading();
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.canvass{
  position: absolute;
  overflow: hidden;
  z-index: -9999;
}
.login {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: black;
  //background-image: url('~@/assets/image/login.jpg');
  background-size: 100% 100%;
}
.login-card {
  padding: 30px 50px;
  border-radius: 6px;
  //background-color: black;
  background: rgba(0,0,0,0.2);
  z-index: 1;
}
.login-header {
  display: flex;
  align-items: center;
  .login-logo {
    width: 44px;
    height: 45px;
    background-image: url('~@/assets/image/logo.png');
    background-size: 100% 100%;
  }
  .login-title {
    margin-left: 10px;
  }
  .welcome {
    line-height: 33px;
    color: rgba(255,255,255,0.85);
  }
  .system-name {
    font-size: 21px;
    line-height: 33px;
    color: rgba(255,255,255,0.85);
  }
  .system-desc {
    line-height: 22px;
    color: rgba(255,255,255,0.45);
  }
}
.login-form {
  margin-top: 30px;
  color: white;
  background-color:transparent
}
.compony-info {
  position: absolute;
  bottom: 20px;
  color: white;
  z-index: 1;
}
</style>